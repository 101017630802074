import { AxiosResponse } from 'axios';
import { Disease, DiseaseName } from '../model/disease';
import { FreeDiseases } from '../model/freeDiseases';
import { api } from './api';

export const diseaseApi = () => {
  const getDiseaseNameById = (id: number): Promise<AxiosResponse<DiseaseName>> => {
    return api.get<DiseaseName>(`/diseases/simple/${id}`);
  };
  const getFreeDiseases = (): Promise<AxiosResponse<FreeDiseases>> => {
    return api.get<FreeDiseases>('/free-diseases');
  };
  const getDisease = (diseaseId: number): Promise<AxiosResponse<Disease>> => {
    return api.get<Disease>(`/diseases/markdown/${diseaseId}`);
  };

  return {
    getDiseaseNameById,
    getFreeDiseases,
    getDisease
  };
};

export default diseaseApi();

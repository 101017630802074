import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Row } from 'reactstrap';
import { compose } from 'redux';
import { HomeListItem, HomeListItemType } from '../../model/HomeListItem';
import { User } from '../../model/user';
import { IRootState } from '../../reducer';
import { logoutRequest } from '../../reducer/authentication/actions';
import { openPaidDiseaseModal } from '../../reducer/globalModal/actions';
import FavoritesService from '../../services/favoritesService';
import NotesService from '../../services/notesService';
import AnnotationsList from './annotations-list';
import FavoritesList from './favorites-list';
import './home.scss';

export interface HomeProps extends RouteComponentProps<{}> {
    isAuthenticated?: boolean;
    logout: () => void;
    openPaidDiseaseModal: () => void;
    hasSubscription: boolean;
    account?: User;
    t: any;
}

export interface HomeState {
    categoryHover?: string;
    hasSubscription?: boolean;
    isLoaded?: boolean;

    favorites: HomeListItem[];
    notes: HomeListItem[];
}

export class Home extends React.Component<HomeProps, HomeState> {
    constructor(props: Readonly<HomeProps>, context?: any) {
        super(props, context);

        this.state = {
            hasSubscription: props.account && props.account.customer && props.account.customer.hasSubscription,
            favorites: [],
            notes: []
        };
    }

    componentDidMount() {
        this.getFavorites();
        this.getNotes();
        if (this.props.isAuthenticated === true) {
            this.setState({ isLoaded: true });
        }
    }

    componentWillReceiveProps(props: HomeProps) {
        if (!props.isAuthenticated) {
            this.logoutFunction();
        }
        if (!this.state.isLoaded) {
            this.setState({
                hasSubscription: props.account && props.account.customer && props.account.customer.hasSubscription,
                isLoaded: true
            });
        }
        if (
            props.account != null &&
            props.account.customer != null &&
            props.account.customer.hasSubscription !== this.state.hasSubscription
        ) {
            this.setState({
                hasSubscription: props.account && props.account.customer && props.account.customer.hasSubscription
            });
        }
    }

    private getFavorites = async () => {
        const response: HomeListItem[] = await FavoritesService.getFavorites();
        this.setState({
            favorites: response
        });
    };
    private getNotes = async () => {
        const response: HomeListItem[] = await NotesService.getNotes();
        this.setState({
            notes: response
        });
    };

    private renderHomeIcon = (category: string, image: string, label: string, isFree?: boolean, params?: any) => {
        const { hasSubscription } = this.state;
        return (
            <Link
                className={this.getCategoryClass(category)}
                onMouseEnter={() => this.onMouseEnterCategory(category)}
                onMouseLeave={this.onMouseLeaveCategory}
                onClick={() => (params != null ? this.props.history.push(`/${category}`, params) : undefined)}
                to={params != null ? '/' : `/${category}`}
            >
                <div className={`${image} category-img`} />
                <span className={'category-label'}>{this.props.t(label)}</span>
                {isFree && !hasSubscription && <div className="free-flag-img" />}
            </Link>
        );
    };

    private onHomeListItemClick = (item: HomeListItem): void => {
        switch (item.type) {
            case HomeListItemType.CID:
                this.props.history.push(`/cid/${item.itemId}`);
                break;
            case HomeListItemType.DISEASE:
                if (item.isFree || this.state.hasSubscription) {
                    this.props.history.push(`/disease/${item.itemId}`);
                } else {
                    this.props.openPaidDiseaseModal();
                }
                break;
            case HomeListItemType.ILLUSTRATED_PROCEDURE:
                if (item.isFree || this.state.hasSubscription) {
                    this.props.history.push(`/procedure/${item.itemId}`);
                } else {
                    this.props.openPaidDiseaseModal();
                }
                break;
            case HomeListItemType.SEMIOLOGY:
                if (item.isFree || this.state.hasSubscription) {
                    this.props.history.push(`/semiology/${item.itemId}`);
                } else {
                    this.props.openPaidDiseaseModal();
                }
                break;
            case HomeListItemType.SCORE:
                if (item.isFree || this.state.hasSubscription) {
                    this.props.history.push(`/scores/${item.itemId}`);
                } else {
                    this.props.openPaidDiseaseModal();
                }
                break;
            case HomeListItemType.CRITERIA:
                if (item.isFree || this.state.hasSubscription) {
                    this.props.history.push(`/criteria/${item.itemId}`);
                } else {
                    this.props.openPaidDiseaseModal();
                }
                break;
            case HomeListItemType.LABORATORY:
                if (item.isFree || this.state.hasSubscription) {
                    this.props.history.push(`/laboratory/${item.itemId}`);
                } else {
                    this.props.openPaidDiseaseModal();
                }
                break;
            case HomeListItemType.MEDICAL_CALCULATOR:
                if (item.isFree || this.state.hasSubscription) {
                    this.props.history.push({
                        pathname: `/calculator/${item.itemId}`,
                        state: { fromAnnotation: true }
                    });
                } else {
                    this.props.openPaidDiseaseModal();
                }
                break;
            case HomeListItemType.MEDICINE_LEAFLET:
                this.props.history.push(`/smart-bulas/${item.itemId}`);
                break;
            case HomeListItemType.PEDIATRIC_DOSE:
                this.props.history.push(`/pediatric-dose/${item.itemId}`);
                break;
            case HomeListItemType.SUS:
                this.props.history.push(`/sus/${item.itemId}`);
                break;
            default:
                break;
        }
    };

    private onHomeListItemAnnotationClick = (item: HomeListItem): void => {
        switch (item.type) {
            case HomeListItemType.DISEASE:
                if (item.isFree || this.state.hasSubscription) {
                    this.props.history.push({ pathname: `/disease/${item.itemId}`, state: { fromAnnotation: true } });
                } else {
                    this.props.openPaidDiseaseModal();
                }
                break;
            case HomeListItemType.SEMIOLOGY:
                if (item.isFree || this.state.hasSubscription) {
                    this.props.history.push({ pathname: `/semiology/${item.itemId}`, state: { fromAnnotation: true } });
                } else {
                    this.props.openPaidDiseaseModal();
                }
                break;
            case HomeListItemType.SCORE:
                if (item.isFree || this.state.hasSubscription) {
                    this.props.history.push({ pathname: `/scores/${item.itemId}`, state: { fromAnnotation: true } });
                } else {
                    this.props.openPaidDiseaseModal();
                }
                break;
            case HomeListItemType.CRITERIA:
                if (item.isFree || this.state.hasSubscription) {
                    this.props.history.push({
                        pathname: `/criteria/${item.itemId}`,
                        state: { fromAnnotation: true }
                    });
                } else {
                    this.props.openPaidDiseaseModal();
                }
                break;
            case HomeListItemType.LABORATORY:
                if (item.isFree || this.state.hasSubscription) {
                    this.props.history.push({
                        pathname: `/laboratory/${item.itemId}`,
                        state: { fromAnnotation: true }
                    });
                } else {
                    this.props.openPaidDiseaseModal();
                }
                break;
            case HomeListItemType.ILLUSTRATED_PROCEDURE:
                if (item.isFree || this.state.hasSubscription) {
                    this.props.history.push({ pathname: `/procedure/${item.itemId}`, state: { fromAnnotation: true } });
                } else {
                    this.props.openPaidDiseaseModal();
                }
                break;
            case HomeListItemType.PEDIATRIC_DOSE:
                if (item.isFree || this.state.hasSubscription) {
                    this.props.history.push({
                        pathname: `/pediatric-dose/${item.itemId}`,
                        state: { fromAnnotation: true }
                    });
                } else {
                    this.props.openPaidDiseaseModal();
                }
                break;
            case HomeListItemType.MEDICAL_CALCULATOR:
                if (item.isFree || this.state.hasSubscription) {
                    this.props.history.push({
                        pathname: `/calculator/${item.itemId}`,
                        state: { fromAnnotation: true }
                    });
                } else {
                    this.props.openPaidDiseaseModal();
                }
                break;
            case HomeListItemType.MEDICINE_LEAFLET:
                this.props.history.push({ pathname: `/smart-bulas/${item.itemId}`, state: { fromAnnotation: true } });
                break;
            default:
                break;
        }
    };
    logoutFunction = () => {
        this.props.logout();
        this.props.history.push('/');
    };
    private onMouseEnterCategory = (category: string): void => {
        this.setState({
            categoryHover: category
        });
    };
    private onMouseLeaveCategory = (): void => {
        this.setState({ categoryHover: undefined });
    };
    private getCategoryClass = (category: string): string => {
        const { categoryHover, hasSubscription } = this.state;
        let categoryCardClass: string = 'category-card';
        if (hasSubscription) {
            categoryCardClass = 'category-card-has-subscription';
        }
        const isHoveringCategory: boolean = categoryHover != null;
        if (isHoveringCategory) {
            if (categoryHover === category) {
                return categoryCardClass;
            }
            return `${categoryCardClass} category-card--faded`;
        }
        return categoryCardClass;
    };

    render() {
        const { account } = this.props;
        const { hasSubscription, isLoaded, favorites, notes } = this.state;
        if (!isLoaded || !account || !account.customer) {
            return <div />;
        }
        return (
            <div className="main-container"
                 style={hasSubscription ? {} : { paddingTop: 40, paddingLeft: 20, paddingRight: 20 }}>
                <div className="justify-content-center" style={{ width: '100%' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                        {!hasSubscription &&
                            <a className="subscription-banner-img" href="/subscription" style={{ marginRight: 26 }} />}
                        <div style={{ display: 'flex', flex: 1, flexDirection: 'column', width: '100%' }}>
                            <div
                                className={hasSubscription ? 'categories-background' : ''}
                                style={{ display: 'flex', flexDirection: 'column' }}
                            >
                                <div style={{ paddingTop: '22px' }}>
                                    {hasSubscription &&
                                        <span className="categories-title">{this.props.t('home.categories')}</span>}
                                    <Row
                                        style={
                                            hasSubscription
                                                ? { marginTop: 22, marginRight: '10%', marginLeft: '225px' }
                                                : { display: 'flex' }
                                        }
                                    >
                                        {this.renderHomeIcon('specialty', 'specialties-img', 'home.specialties')}
                                        {this.renderHomeIcon('flashcard-dashboard', 'flashcard-img', 'home.flashcard', false)}
                                        {this.renderHomeIcon('diagnapp', 'diagnapp-img', 'home.diagnapp')}
                                        {this.renderHomeIcon('smart-bulas', 'smart-leaflets-img', 'home.smartLeaflets', true)}
                                        {this.renderHomeIcon('interaction', 'medicine-interactions-img', 'home.medicineInteractions')}
                                        {this.renderHomeIcon('pediatric-dose', 'pediatric-dose-img', 'home.pediatricDoses')}
                                        {this.renderHomeIcon('procedure', 'procedure-layout-img', 'home.procedure')}
                                        {this.renderHomeIcon('calculator', 'calculator-img', 'home.calculator')}
                                        {this.renderHomeIcon('laboratory', 'laboratory-img', 'home.laboratory')}
                                        {this.renderHomeIcon('scores', 'score-img', 'home.score')}
                                        {this.renderHomeIcon('criteria', 'criteria-img', 'home.criteria')}
                                        {this.renderHomeIcon('semiology', 'semiology-img', 'home.semiology')}
                                        {this.renderHomeIcon('protocols', 'protocols-img', 'home.protocols')}
                                        {this.renderHomeIcon('toxicology', 'toxicology-img', 'home.toxicology')}
                                        {this.renderHomeIcon('vaccination', 'vaccination-img', 'home.vaccination')}
                                        {this.renderHomeIcon('cid', 'cid-code-img', 'home.cidCode', true)}
                                        {this.renderHomeIcon('sus', 'sus-procedure-img', 'home.susProcedure', true)}
                                        {this.renderHomeIcon('divider-on-duty', 'divider-on-duty-img', 'home.dividerOnDuty', true)}
                                    </Row>
                                </div>
                            </div>
                            {!hasSubscription && <div className="line-separator" />}
                            <div className="lists-container"
                                 style={hasSubscription ? { width: 990, alignSelf: 'center' } : {}}>
                                <FavoritesList
                                    specialtiesList={favorites.filter(it => it.type === HomeListItemType.DISEASE)}
                                    leafletList={favorites.filter(it => it.type === HomeListItemType.MEDICINE_LEAFLET)}
                                    cidList={favorites.filter(it => it.type === HomeListItemType.CID)}
                                    susList={favorites.filter(it => it.type === HomeListItemType.SUS)}
                                    procedureList={favorites.filter(it => it.type === HomeListItemType.ILLUSTRATED_PROCEDURE)}
                                    semiologyList={favorites.filter(it => it.type === HomeListItemType.SEMIOLOGY)}
                                    laboratoryList={favorites.filter(it => it.type === HomeListItemType.LABORATORY)}
                                    doseList={favorites.filter(it => it.type === HomeListItemType.PEDIATRIC_DOSE)}
                                    calculatorList={favorites.filter(it => it.type === HomeListItemType.MEDICAL_CALCULATOR)}
                                    scoreList={favorites.filter(it => it.type === HomeListItemType.SCORE)}
                                    criteriaList={favorites.filter(it => it.type === HomeListItemType.CRITERIA)}
                                    onItemClick={this.onHomeListItemClick}
                                    history={this.props.history}
                                />
                                <div className="vertical-line" />
                                <AnnotationsList
                                    specialtiesList={notes.filter(it => it.type === HomeListItemType.DISEASE)}
                                    leafletList={notes.filter(it => it.type === HomeListItemType.MEDICINE_LEAFLET)}
                                    procedureList={notes.filter(it => it.type === HomeListItemType.ILLUSTRATED_PROCEDURE)}
                                    semiologyList={notes.filter(it => it.type === HomeListItemType.SEMIOLOGY)}
                                    laboratoryList={notes.filter(it => it.type === HomeListItemType.LABORATORY)}
                                    calculatorList={notes.filter(it => it.type === HomeListItemType.MEDICAL_CALCULATOR)}
                                    doseList={notes.filter(it => it.type === HomeListItemType.PEDIATRIC_DOSE)}
                                    scoreList={notes.filter(it => it.type === HomeListItemType.SCORE)}
                                    criteriaList={notes.filter(it => it.type === HomeListItemType.CRITERIA)}
                                    onItemClick={this.onHomeListItemAnnotationClick}
                                    history={this.props.history}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ authentication }: IRootState) => ({
    isAuthenticated: authentication.isAuthenticated,
    account: authentication.account,
    logout: logoutRequest
});

const mapDispatchToProps = {
    openPaidDiseaseModal
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(Home) as React.ComponentType<any>;

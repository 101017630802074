import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';
import Loading from '../../../components/loading/loading';
import { PerformanceData } from '../../../model/flashcard/performance-data';
import { IRootState } from '../../../reducer';
import { openPaidFlashcardModal } from '../../../reducer/globalModal/actions';
import FlashcardCustomerService from '../../../services/flashcard-customer.service';
import FlashcardPerformanceChart from '../flashcard-statistic/flashcard-performance-chart';
import {
    ArrowBack,
    ArrowIcon,
    CardContainer,
    CategoriesContainer,
    CategoriesTitle,
    CategoryContainer,
    CategoryIcon,
    CategoryTitle,
    Container,
    DataIcon,
    DatasContainer,
    DataSubtitle,
    DataValue,
    HeaderContainer,
    HeaderText,
    ItemContainer,
    ItemIcon,
    ItemsContainer,
    ItemTitle,
    Line,
    MainContainer,
    RightCardSideContainer,
    SeeMoreContainer,
    SeeMoreLabel
} from './flashcard-dashboard-styles';
import './flashcard-dashboard.scss';

interface Props extends RouteComponentProps<{}, {}, { id?: number }> {
    t: any;
    hasSubscription?: boolean;
    openPaidModal: () => void;
}

const FlashcardDashboard = (props: Props) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [performanceData, setPerformanceData] = useState<PerformanceData>({ answers: [] });
    const [consecutiveDaysCount, setConsecutiveDaysCount] = useState<number>(0);
    const [totalCards, setTotalCards] = useState<number>(0);

    useEffect(() => {
        updatePerformanceData();
    }, []);


    const updatePerformanceData = () => {
        FlashcardCustomerService.getFlashcardCustomers().then(flashcards => {
            const dates = _.uniq(flashcards.map(it => moment(it.lastModifiedDate!).format('MM-DD-yyyy')).sort((a, b) => a.localeCompare(b)));
            for (let i = 1; i < dates.length; i++) {
                const previousDate = dates[i - 1];
                const currentDate = dates[i];
                const previousDateIsYesterday = !moment(previousDate).add(1, 'days').isSame(currentDate);
                if (previousDateIsYesterday) {
                    setConsecutiveDaysCount(i);
                    break;
                }
            }
            const answers: any[] = [];
            flashcards.forEach(it => {
                for (let i = 0; i < (it.timesAnswered ?? 1); i++) {
                    answers.push({
                        flashcardId: it.flashcardId,
                        answer: it.answer
                    });
                }
            });
            setTotalCards(answers.length);
            setPerformanceData({ answers });
        }).then(() => setIsLoading(false));
    };

    const showEnableSoonToast = () => {
        toast((t) => (<span className={'toast-text'}>
                    {props.t('flashcardDashboard.enabledSoon')}
                </span>), {
            style: {
                border: '1px solid #F59E24',
                backgroundColor: '#EF7D00'
            },
            position: 'bottom-center'
        });
    };

    const renderCategory = (categoryColor: string, categoryIcon: string, title: string, route: string) => {
        const onPressCategory = () => {
            if (title === 'custom') {
                showEnableSoonToast();
                return;
            }
            if (title !== 'random' && !props.hasSubscription) {
                props.openPaidModal();
                return;
            }
            props.history.push(route);
        };
        return (
            <CategoryContainer color={categoryColor} onClick={() => onPressCategory()}>
                <CategoryIcon className={categoryIcon} />
                <CategoryTitle>{props.t(`flashcardDashboard.categories.${title}`)}</CategoryTitle>
            </CategoryContainer>
        );
    };

    const renderData = (title: string, dataIcon: string, value: number) => {
        return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <DataIcon className={dataIcon} />
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <DataValue>{value}</DataValue>
                    <DataSubtitle>{props.t(title)}</DataSubtitle>
                </div>
            </div>
        );
    };

    const renderItem = (title: string, dataIcon: string, route: string) => {
        const onPressItem = () => {
            if (title === 'flashcardDashboard.myCards') {
                showEnableSoonToast();
                return;
            }
            props.history.push(route);
        };
        return (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', cursor: 'pointer' }}
                 onClick={onPressItem}>
                <ItemContainer>
                    <ItemIcon className={dataIcon} />
                </ItemContainer>
                <ItemTitle>{props.t(title)}</ItemTitle>
            </div>
        );
    };

    return isLoading ? (
        <div className="disease-screen-container">
            <Loading />
        </div>
    ) : (
        <MainContainer>
            <Container>
                <HeaderContainer>
                    <ArrowBack className={'black-caret-left-img'} onClick={() => props.history.goBack()} />
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <HeaderText>{props.t('flashcardDashboard.title')}</HeaderText>
                    </div>
                </HeaderContainer>
                <CardContainer>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                        <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                            <FlashcardPerformanceChart height={250} width={250} data={performanceData} {...props} />
                            <DatasContainer>
                                {renderData('flashcardDashboard.consecutiveDays', 'yellow-lightning-img', consecutiveDaysCount)}
                                <Line />
                                {renderData('flashcardDashboard.totalCards', 'orange-pencil-line-img', totalCards)}
                            </DatasContainer>
                        </div>
                        <SeeMoreContainer>
                            <SeeMoreLabel onClick={() => props.history.push('/flashcard-dashboard-statistic')}>
                                {props.t('flashcardDashboard.seeMore')}
                            </SeeMoreLabel>
                            <ArrowIcon className={'dark-grey-line-arrow-right-img'} />
                        </SeeMoreContainer>
                        <ItemsContainer>
                            {renderItem('flashcardDashboard.favorite', 'orange-star-img', '/flashcard-favorite')}
                            {renderItem('flashcardDashboard.myCards', 'orange-flashcards-img', '')}
                            {renderItem('flashcardDashboard.performance', 'orange-chart-line-up-img', '/flashcard-dashboard-statistic')}
                        </ItemsContainer>
                    </div>
                    <RightCardSideContainer>
                        <CategoriesTitle>{props.t('flashcardDashboard.selectCategory')}</CategoriesTitle>
                        <CategoriesContainer>
                            {renderCategory('#F59E24', 'white-binoculars-img', 'extensive', '/flashcard-extensive-dashboard')}
                            {renderCategory('#C25576', 'white-heart-img', 'intensive', '/flashcard-intensive-dashboard')}
                            {renderCategory('#3D8789', 'white-switch-button-img', 'custom', '')}
                            {renderCategory('#2572B6', 'white-gear-img', 'random', '/flashcard-random')}
                        </CategoriesContainer>
                    </RightCardSideContainer>
                </CardContainer>
            </Container>
        </MainContainer>
    );
};

const mapStateToProps = ({ authentication }: IRootState) => ({
    hasSubscription: authentication.account?.customer?.hasSubscription
});

const mapDispatchToProps = {
    openPaidModal: openPaidFlashcardModal
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(FlashcardDashboard) as React.ComponentType<any>;

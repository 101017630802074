import styled from 'styled-components';

export const MainContainer = styled.div`
    height: calc(100vh - 80px - 50px);
    display: flex;
    padding-top: 25px;
    justify-content: center;
    align-items: center;
    background-color: #f9fafa;
`;

export const Container = styled.div`
    height: 100%;
    width: 60vw;
    display: flex;
    flex-direction: column;
`;

export const ArrowBack = styled.div`
    height: 24px;
    width: 24px;
    margin-right: 10px;
    cursor: pointer;
`;

export const EyeIcon = styled.div`
    height: 32px;
    width: 32px;
    margin-right: 12px;
    cursor: pointer;
`;

export const HeaderRightContainer = styled.div`
    left: 70vw;
    width: 5vw;
    position: absolute;
    margin-top: 5px;
    display: flex;
    justify-content: flex-end;
`;

export const HeaderContainer = styled.div`
    height: 15%;
    width: 100%;
    display: flex;
    align-items: center;
`;

export const HeaderText = styled.span`
    color: #181f22;
    font-size: 24px;
    font-family: Raleway;
    font-weight: 600;
    line-height: 31.2px;
    letter-spacing: 0.48px;
    word-wrap: break-word;
`;

export const HeaderSubtitle = styled.span`
    color: #a3b1b5;
    font-size: 14px;
    font-family: Raleway;
    font-weight: 600;
    line-height: 16.8px;
    word-wrap: break-word;
`;

export const PracticeText = styled.span`
    color: #EF7D00;
    font-size: 18px;
    font-weight: 600;
    font-family: Raleway;
    line-height: 19.20px;
    position: absolute;
    left: 14.5vw;
`;

export const PracticeButton = styled.div`
    width: 35vw;
    border: 1.2px solid #EF7D00;
    border-radius: 32px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
`;

export const PracticeIcon = styled.div`
    height: 24px;
    width: 24px;
    margin-right: 16px;
`;

export const EmptyIcon = styled.div`
    height: 12vw;
    width: 12vw;
`;

export const EmptyText = styled.span`
    margin-top: 2vh;
    color: #414E52;
    font-size: 1.2rem;
    font-weight: 600;
    font-family: Raleway;
    line-height: 19.20px;
`;


import React, { useCallback } from 'react';
import toast from 'react-hot-toast';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { FlashcardView } from '../../../model/flashcard/flashcard';
import FlashcardContent from '../flashcard-carousel/flashcard-content';
import { useFlashcardViewContext } from '../flashcard-carousel/flashcard.provider';
import '../flashcard.scss';
import {
    CardContainer,
    CardsContainer,
    ChildrenContainer,
    Icon,
    MainContainer,
    MarkdownContainer
} from './flashcard-list.styles';

interface Props extends RouteComponentProps<{}, {}, {}>, WithTranslation {
    width?: number;
    fontSize: number;
    children?: React.ReactNode;
}

const FlashcardList = (props: Props) => {
    const { cards, updateCard, favoriteFlashcard } = useFlashcardViewContext();

    const changeCardAnswerVisualization = (card: FlashcardView, index: number) => {
        card.seeAnswerInList = !card.seeAnswerInList;
        updateCard(index, card);
    };

    const onFavoriteFlashcard = (index: number, favoriteIsActive: boolean) => {
        favoriteFlashcard(index);
        const message = favoriteIsActive ? 'removeFavoriteMessage' : 'addFavoriteMessage';
        const toastStyle = favoriteIsActive ? {
            border: '1px solid #9ABFCB',
            backgroundColor: '#648A97'
        } : {
            border: '1px solid #F59E24',
            backgroundColor: '#EF7D00'
        };
        toast((t) => (<span className={'toast-text'}>
                    {props.t(`flashcardFavorite.${message}`)}
                </span>), {
            style: toastStyle,
            position: 'bottom-center'
        });
    };

    const renderCard = useCallback((card: FlashcardView, index: number) => {
        const favoriteIsActive = cards[index]?.favorite?.isActive;
        return (
            <CardContainer onClick={() => changeCardAnswerVisualization(card, index)}>
                <Icon onClick={() => onFavoriteFlashcard(index, favoriteIsActive!)}
                      className={favoriteIsActive ? 'yellow-full-star-img ' : 'grey-star-img'} />
                <MarkdownContainer>
                    <FlashcardContent card={card} scrollOff fontSize={props.fontSize}
                                      seeAnswer={card.seeAnswerInList} />
                </MarkdownContainer>
                <Icon className={`grey-arrow-img-${card.seeAnswerInList ? 'up' : 'down'}`} />
            </CardContainer>
        );
    }, [cards, props.fontSize]);


    return (
        <MainContainer width={props.width}>
            <CardsContainer fullHeight={props.children == null} width={props.width}>
                {cards.map((card, index) => renderCard(card, index))}
            </CardsContainer>
            {props.children && <ChildrenContainer width={props.width}>
                {props.children}
            </ChildrenContainer>}
        </MainContainer>
    );
};

export default withTranslation()(FlashcardList);

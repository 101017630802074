import { AxiosResponse } from 'axios';
import { FlashcardView } from '../model/flashcard/flashcard';
import { api } from './api';

const FlashcardApi = () => {
    const getByDiseasesId = (ids: number[]): Promise<AxiosResponse<FlashcardView[]>> => {
        return api.get<FlashcardView[]>(`/flashcards/specialities?ids=${ids.join(',')}`);
    };

    const getFavoriteFlashcards = (): Promise<AxiosResponse<FlashcardView[]>> => {
        return api.get<FlashcardView[]>('/flashcards/favorites');
    };

    const getFlashcardByIds = (ids: number[]): Promise<AxiosResponse<FlashcardView[]>> => {
        return api.get<FlashcardView[]>(`/flashcards/info?ids=${ids.join(',')}`);
    };

    return {
        getFlashcardByIds,
        getByDiseasesId,
        getFavoriteFlashcards
    };
};

export default FlashcardApi();

import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';
import FlashcardApi from '../../../../api/flashcard.api';
import Loading from '../../../../components/loading/loading';
import FlashcardRunMode from '../../../../model/enums/flashcard-run-mode';
import { FlashcardRun, FlashcardRunMetric } from '../../../../model/flashcard/flashcard-run';
import FlashcardRunService from '../../../../services/flashcard-run.service';
import { getDateNowTimestamp } from '../../../../shared/util/utils';
import FlashcardHeader from '../../components/flashcard-header';
import FlashcardToolBar from '../../components/flashcard-tool-bar';
import FlashcardCarousel from '../../flashcard-carousel/flashcard-carousel';
import { useFlashcardViewContext } from '../../flashcard-carousel/flashcard.provider';
import FlashcardList from '../../flashcard-list/flashcard-list';
import FlashcardPerformance from '../../flashcard-statistic/flashcard-performance';
import { Container, MainContainer } from '../../flashcard-styles';
import '../../flashcard.scss';

interface Props extends RouteComponentProps<{}, {}, { id?: number }> {
    t: any;
    hasSubscription?: boolean;
    openPaidModal: () => void;
    onPressPerformanceButton?: () => void;
    hideCreateCardButton?: boolean;
}

const FlashcardExtensive = (props: Props) => {
    const {
        setCards,
        cards,
        startTimestamp,
        setCurrentIndex,
        fontSize,
        updatedPerformanceData,
        performanceData
    } = useFlashcardViewContext();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [viewMode, setViewMode] = useState<'LIST' | 'PERFORMANCE' | 'CARD'>('CARD');
    const [metric, setMetric] = useState<FlashcardRunMetric>();
    const [flashcardRun, setFlashcardRun] = useState<FlashcardRun>();

    useEffect(() => {
        const {
            match: { params }
        } = props;
        const metricId = Number((params as any).metricId);
        FlashcardRunService.getFlashcardRunByMode(FlashcardRunMode.EXTENSIVE).then(currentRun => {
            if (currentRun != null) {
                const newMetric: FlashcardRunMetric = (currentRun.metric as FlashcardRunMetric[]).find(it => metricId === it.id)!;
                setFlashcardRun(currentRun);
                setMetric(newMetric);
                setFlashcardInformation(newMetric);
            }
        });
    }, []);

    useEffect(() => {
        const allCardsAnswered = cards.filter(it => it.answer != null).length === cards.length;
        if ((allCardsAnswered && cards.length > 0 && !isLoading) ||
            (allCardsAnswered && cards.length > 0 && metric?.isFinished === true)) {
            finishReview();
        }
    }, [cards]);

    const onBack = () => {
        if (metric?.isFinished) {
            props.history.goBack();
            return;
        }
        const updatedExtensiveRun = getUpdatedExtensiveRun();
        setIsLoading(true);
        FlashcardRunService.update(updatedExtensiveRun)
            .then(() => props.history.goBack())
            .catch(err => console.log(err));
    };

    const setFlashcardInformation = (newMetric: FlashcardRunMetric) => {
        const isCheckpoint = newMetric.id % 1 === 0.5;
        const ids: number[] = isCheckpoint ? newMetric.revisionItemsId ?? [] : newMetric.questionIds;
        FlashcardApi.getByDiseasesId(ids).then(response => {
            let newCards = response.data;
            const answers = newMetric.answers;

            answers.map(answer => {
                const cardIndex = newCards.findIndex(it => it.id === answer.flashcardId!);
                if (cardIndex > -1) {
                    newCards[cardIndex].seeAnswer = true;
                    newCards[cardIndex].answer = answer.answer!;
                }
            });

            if(!isCheckpoint) {
                newCards = newCards.sort((a, b) => ids.indexOf(a.disease!.id!) - ids.indexOf(b.disease!.id!));
            }
            console.log({newCards})
            const lastIndexWithoutAnswer = newCards.findIndex(card => card.seeAnswer !== true);
            if (lastIndexWithoutAnswer > -1) {
                setCurrentIndex(lastIndexWithoutAnswer);
            }
            setCards(newCards);
        }).finally(() => setIsLoading(false));
    };

    const goToPerformanceScreen = () => {
        updatedPerformanceData();
        setViewMode('PERFORMANCE');
    };

    const finishReview = () => {
        updatedPerformanceData();
        setViewMode('PERFORMANCE');
        const updatedExtensiveRun = getUpdatedExtensiveRun();
        FlashcardRunService.update(updatedExtensiveRun)
            .catch(err => console.log(err));
    };

    const getUpdatedExtensiveRun = () => {
        const newMetrics = flashcardRun!.metric as FlashcardRunMetric[];
        const metricIndex = newMetrics.findIndex(it => it.id === metric!.id);
        const secondsSpent = (getDateNowTimestamp() - startTimestamp) / 1000;
        const newAnswers = cards
            .filter(it => it.answer != null)
            .map(it => {
                return { flashcardId: it.id, answer: it.answer };
            });
        const answeredQuestions = newAnswers.length;
        newMetrics[metricIndex].answers = newAnswers;
        newMetrics[metricIndex].answeredQuestions = answeredQuestions;
        newMetrics[metricIndex].remainingQuestions = cards.length - answeredQuestions;
        newMetrics[metricIndex].secondsSpent = secondsSpent;
        newMetrics[metricIndex].isFinished = newMetrics[metricIndex].remainingQuestions === 0;
        const newFlashcardRun = { ...flashcardRun };
        newFlashcardRun.metric = newMetrics;
        setFlashcardRun(newFlashcardRun);
        return newFlashcardRun;
    };

    const onPressBack = (viewMode === 'PERFORMANCE' || viewMode === 'LIST') && metric?.isFinished === false ? () => setViewMode('CARD') : () => onBack();
    return isLoading ? (
        <div className="disease-screen-container">
            <Loading />
        </div>
    ) : (
        <MainContainer>
            <Container>
                <FlashcardHeader
                    {...props}
                    viewMode={viewMode}
                    onPressBack={onPressBack}
                    title={props.t('flashcardExtensive.title')}
                    subtitle={props.t('flashcardExtensive.subtitle')}
                />
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    {viewMode !== 'PERFORMANCE' && <FlashcardToolBar
                        t={props.t}
                        history={props.history}
                        location={props.location}
                        match={props.match}
                        viewMode={viewMode}
                        hideFavoriteButton={viewMode === 'LIST'}
                        hideRandomButton
                        hideFinishButton
                        onPressConsultButton={() => setViewMode(viewMode === 'LIST' ? 'CARD' : 'LIST')}
                        onPressFinishButton={() => finishReview()}
                    />}
                    {viewMode === 'CARD' &&
                        <FlashcardCarousel {...props}
                                           fontSize={fontSize}
                                           onPressPerformanceButton={() => goToPerformanceScreen()} />}
                    {viewMode === 'LIST' && <FlashcardList {...props} fontSize={fontSize} />}
                    {viewMode === 'PERFORMANCE' &&
                        <FlashcardPerformance {...props} data={performanceData} />}

                </div>
            </Container>
        </MainContainer>
    );
};

const mapDispatchToProps = {};

export default compose(connect(null, mapDispatchToProps), withTranslation())(FlashcardExtensive) as React.ComponentType<any>;

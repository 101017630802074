import { Slider, withStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Link, RouteComponentProps } from 'react-router-dom';
import '../../../App.scss';
import { FlashcardView } from '../../../model/flashcard/flashcard';
import { getDateNowTimestamp } from '../../../shared/util/utils';
import FlashcardFontResize from '../components/flashcard-font-resize';
import '../flashcard.scss';
import FlashcardAnswers from './flashcard-answers';
import FlashcardCarouselPagination from './flashcard-carousel-pagination';
import {
    AnswersContainer,
    ArrowUpRightIcon,
    CardBottomContainer,
    CardContainer,
    ChartLineIcon,
    HeaderContainer,
    IconsContainer,
    MarkdownContainer,
    MathPlusIcon,
    SeeAnswerButton,
    SeeAnswerText,
    SeeContentText
} from './flashcard-carousel.styles';
import FlashcardContent from './flashcard-content';
import { useFlashcardViewContext } from './flashcard.provider';

interface Props extends RouteComponentProps<{}, {}, {}>, WithTranslation {
    fontSize: number;
    onPressPerformanceButton?: () => void;
    hideCreateCardButton?: boolean;
}

export const FontSizeSlider = withStyles({
    root: {
        color: '#e7e7e7',
        height: 4,
        opacity: 1
    },
    thumb: {
        width: 16,
        height: 16,
        boxShadow: '0px 2px 6px 0px rgba(0, 0, 0, 0.32)',
        backgroundColor: '#ffffff',
        marginTop: -6
    },
    mark: {
        backgroundColor: '#e7e7e7',
        height: 9,
        width: 4,
        borderRadius: 3,
        marginTop: -2.02,
        zIndex: -1
    },
    active: {},
    track: {
        height: 4,
        borderRadius: 3,
        opacity: 1,
        backgroundImage: 'linear-gradient(90deg, rgba(44,147,147,1) 0%, rgba(66,210,206,1) 100%)'
    },
    rail: {
        height: 4,
        borderRadius: 3,
        opacity: 1,
        backgroundColor: '#e7e7e7'
    }
})(Slider);

const FlashcardCarousel = (props: Props) => {
    const [startCardTimestamp, setStartCardTimestamp] = useState<number>(getDateNowTimestamp());
    const { cards, updateCard, setCurrentIndex, currentIndex, diseaseId } = useFlashcardViewContext();
    const { onPressPerformanceButton, fontSize }: Props = props;

    const goToNextNotAnswered = (index: number) => {
        const lastIndex = cards.length - 1;
        let newIndex = index !== lastIndex ? index + 1 : 0;
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < cards.length; i++) {
            if (!cards[newIndex].seeAnswer) {
                updateIndex(newIndex);
                break;
            }
            if (newIndex === lastIndex) {
                updateIndex(0);
                continue;
            }
            newIndex++;
        }
    };

    const updateIndex = (newIndex: number) => {
        if (newIndex < -1 || cards.length === newIndex || newIndex < 0) {
            return;
        }
        setStartCardTimestamp(getDateNowTimestamp());
        setCurrentIndex(newIndex);
    };

    const renderCard = () => {
        const item: FlashcardView = cards[currentIndex];
        if (item == null) {
            return <div />;
        }
        return (
            <CardContainer>
                <HeaderContainer>
                    <Link className="see-content-container"
                          to={`/disease/${diseaseId !== 0 ? diseaseId : item.disease?.id}`}
                          target="_blank">
                        <SeeContentText>{props.t('flashcardCarousel.reviewTheme')}</SeeContentText>
                        <ArrowUpRightIcon className="arrow-up-right-blue-img" />
                    </Link>
                    <IconsContainer>
                        <FlashcardFontResize showIcon />
                        {onPressPerformanceButton && (
                            <ChartLineIcon onClick={() => onPressPerformanceButton()} className="chart-line-up-img" />
                        )}
                        <MathPlusIcon className="plus-circle-img" />
                    </IconsContainer>
                </HeaderContainer>
                <MarkdownContainer>
                    <FlashcardContent card={item} seeAnswer={item.seeAnswer} fontSize={fontSize} />
                </MarkdownContainer>
                <CardBottomContainer>
                    <FlashcardCarouselPagination
                        index={currentIndex + 1}
                        total={cards.length}
                        backPage={() => updateIndex(currentIndex - 1)}
                        nextPage={() => updateIndex(currentIndex + 1)}
                    />
                    <AnswersContainer>
                        {item.seeAnswer ? (
                            <FlashcardAnswers
                                {...props}
                                card={item}
                                index={currentIndex}
                                startCardTimestamp={startCardTimestamp}
                                goToNextNotAnswered={() => goToNextNotAnswered(currentIndex)}
                            />
                        ) : (
                            <SeeAnswerButton onClick={() => updateCard(currentIndex, { ...item, seeAnswer: true })}>
                                <SeeAnswerText>{props.t('flashcardCarousel.seeAnswer')}</SeeAnswerText>
                            </SeeAnswerButton>
                        )}
                    </AnswersContainer>
                </CardBottomContainer>
            </CardContainer>
        );
    };

    return renderCard();
};


export default withTranslation()(FlashcardCarousel);
